export default {
  ab: 772,
  ap: 289,
  bt: 1271,
  by: undefined,
  e24: undefined,
  polaris: undefined,
  pub: undefined,
  sa: 2,
  sport: undefined,
  svd: 90,
  vg: undefined,
};
